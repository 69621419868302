<template>
    <b-modal title="Lista de Token" id="modal-token" size="lg" hide-footer>
        <div class="moda-body">
            <div class="row mb-4 mx-0" style="padding:5px">
                <div class="col-sm-2 text-right">
                    <label for="tokenDelegado" style="white-space: nowrap;">Token Delegado:</label>
                </div>
                <div class="col-sm-5">
                    <input type="text" id="tokenDelegado" class="form-control " v-model="tokenDelegado" placeholder="Token Delegado">
                </div>
                <div class="col-sm-3">
                    <date-picker
                        v-model="fechaVencimiento"
                        class="col-sm-10 px-0"
                        lang="es"
                        format="DD/MM/YYYY"
                        type="date"
                        value-type="date"
                    ></date-picker>
                </div>
                <div class="col-sm-2">
                    <button class="btn th-custom-color" type="button" @click="agregarToken()">Agregar</button>
                </div>
            </div>
            <div class="row mb-2 table-responsive mx-0">
                <table class="table table-bordered">
                    <thead>
                        <th class="text-center">Nro</th>
                        <th class="text-center">Fecha</th>
                        <th class="text-center">Token</th>
                        <th class="text-center">Vence</th>
                        <th class="text-center">Usuario</th>
                        <th class="text-center">Estado</th>
                        <th class="text-center">Quitar</th>
                    </thead>
                    <tbody>
                        <tr v-for="(token, index) in this.lisTokens" :key="index" :style="{ 'text-decoration': (token.estado === 0) ? 'line-through': '' }">                            
                            <td width="5%"> {{ token.numero }} </td>
                            <td width="5%"> {{ token.fecha }} </td>
                            <td width="30%" class="long-text">{{ token.token }} </td>
                            <td width="5%"> {{ token.vence }} </td>
                            <td width="5%"> {{ token.usuario }} </td>
                            <td width="5%"> 
                                <button v-if="token.is_active === 1" class="btn th-custom-color" style="cursor:default" @click="activarDesactivarToken(token, 0)">
                                    Activo
                                </button>
                                <button v-else class="btn btn-danger" style="cursor:default" @click="activarDesactivarToken(token, 1)">Inactivo</button>
                            </td>
                            <td width="5%">
                                <button type="button" @click="eliminar(token)" class="btn btn-danger">X</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn th-custom-color" :disabled="(this.lisTokens.length === 0) ? true : false" 
                type="button"
                @click="store()">Aceptar</button>
        </div>
    </b-modal>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { mapState } from 'vuex';
import { sync } from 'vuex-pathify';
export default {
    name: "theModalListToken",
    data() {
        return {
            tokenDelegado: null,
            fechaVencimiento: new Date(moment()),
        };
    },
    methods: {
        agregarToken() {
            if (this.lisTokens.length === 0) {
                this.lisTokens.push(
                    {
                        id: 0,
                        usuario_id: this.user.id,
                        usuario: this.user.nombre,
                        fecha: moment().format("DD/MM/YYYY"),
                        token: this.tokenDelegado,
                        vence: moment(this.fechaVencimiento).format("DD/MM/YYYY"),
                        estado: 1,
                        is_active: 1,
                        key: new Date().getTime(),
                    }
                );
            } else {
                this.lisTokens.forEach(element => {
                    element.is_active = 0;
                });
                this.lisTokens.unshift(
                    {
                        id: 0,
                        usuario_id: this.user.id,
                        usuario: this.user.nombre,
                        fecha: moment().format("DD/MM/YYYY"),
                        token: this.tokenDelegado,
                        vence: moment(this.fechaVencimiento).format("DD/MM/YYYY"),
                        estado: 1,
                        is_active: 1,
                        key: new Date().getTime(),
                    }
                );
            }
            this.tokenDelegado = null;
            this.fechaVencimiento = new Date(moment());
        },
        activarDesactivarToken(item, estado) {
            let index = this.lisTokens.findIndex((ele) => ele.key === item.key);
            if (index >= 0) {
                this.lisTokens[index].is_active = estado;
            }
            if (estado === 1) {
                // 
                this.lisTokens.forEach(element => {
                    if (element.key !== item.key) {
                        element.is_active = 0;
                    } else {
                        element.estado = 1;
                    }
                });
            }
        },
        store() {
            this.$emit("set-list-token", this.lisTokens);
            this.$bvModal.hide('modal-token');
        },
        eliminar(item) {
            let index = this.lisTokens.findIndex((ele) => ele.key === item.key);
            if (item.id === 0) {
                this.lisTokens.splice(index, 1);
            } else {
                if (index >= 0) {
                    this.lisTokens[index].estado = 0;
                    this.lisTokens[index].is_active = 0;
                }
            }
        },
    },
    computed: {
        ...mapState('main', [
            'user',
        ]),
        ...sync('configuracion/theModalListToken', [
            "lisTokens"
        ]),
    },
    components: {
        DatePicker
    },
}
</script>
<style scoped>
.long-text {
    word-break:break-all !important;
}
</style>